var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Modal, View, StyleSheet, Pressable, ScrollView, } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
export const BottomSheet = (_a) => {
    var { containerStyle, backdropStyle, onBackdropPress = () => null, isVisible = false, modalProps = {}, children, scrollViewProps = {} } = _a, rest = __rest(_a, ["containerStyle", "backdropStyle", "onBackdropPress", "isVisible", "modalProps", "children", "scrollViewProps"]);
    return (React.createElement(Modal, Object.assign({ animationType: "slide", onRequestClose: onBackdropPress, transparent: true, visible: isVisible }, modalProps),
        React.createElement(Pressable, { onPress: onBackdropPress, style: [StyleSheet.absoluteFill, backdropStyle], testID: "RNE__Overlay__backdrop" }),
        React.createElement(SafeAreaView, Object.assign({ style: StyleSheet.flatten([
                styles.safeAreaView,
                containerStyle && containerStyle,
            ]), pointerEvents: "box-none" }, rest),
            React.createElement(View, null,
                React.createElement(ScrollView, Object.assign({}, scrollViewProps), children)))));
};
const styles = StyleSheet.create({
    safeAreaView: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.2)',
        flexDirection: 'column-reverse',
    },
});
BottomSheet.displayName = 'BottomSheet';
